import { getHrefParams } from '@/utils/utils'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['lang'])
  },
  watch: {
    lang () {
      document.title = this.$t('pageTitle')
    }
  },
  methods: {
    setLanguage () {
      const { lang } = getHrefParams()
      const localLang = lang || this.lang || 'zh-CN'
      this.$i18n.locale = localLang
      this.SET_ALL({
        lang: localLang
      })
    },
    showDialog () {
      this.showForm = true
    },
    imgClick () {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
}
