<template>
  <div
    class="form-container"
    :class="{ scroll: scroll }"
  >
    <div
      class="form"
      ref="form"
    >
      <div
        v-if="showClose"
        class="close"
        @click="handleClose"
      ></div>
      <div class="form-input-container">
        <FormInput
          class="form-input"
          v-model="formData.cstName"
          type="text"
          :placeholder="$t('请输入姓名')"
        >
        </FormInput>
        <FormInput
          class="form-input"
          ref="mobileNoInput"
          v-model="formData.mobileNo"
          type="text-number"
          :placeholder="$t('请输入手机号码')"
          :max-length="formData.countryCode === '86' ? 11 : 16"
        >
          <div
            slot="left"
            class="country-code-box"
            :class="{loading}"
            @click="handleShowCountryList"
          >
            <div
              v-if="loading"
              class="loading"
            >
              <span :class="[ {'el-icon-loading': loading}]"></span>
            </div>

            <template v-else>
              <span v-if="formData.countryCode === 'none'">
                Code &nbsp;
              </span>
              <span
                class="code"
                v-else
              >+{{ formData.countryCode }}</span>
            </template>
            <img
              class="arrow"
              src="@/assets/images/banner/down-arrow.png"
              alt=""
            >
            <CountrySelectList
              :visible.sync="showCountryList"
              :select-code.sync="formData.countryCode"
              :list="countryList"
            />
          </div>
        </FormInput>
        <FormInput
          class="form-input"
          v-model="formData.email"
          type="text"
          :placeholder="$t('请输入邮箱')"
        >
        </FormInput>
        <FormInput
          class="form-input"
          v-model="formData.enterpriseName"
          type="text"
          :placeholder="$t('请输入企业名称')"
        >
        </FormInput>
      </div>
      <!-- 单选 -->
      <!-- <RadioList
        :list="radioList"
        :selectType="scroll ? 'white' : ''"
        :select-code.sync="formData.areaCode"
      /> -->
      <!-- 提交按钮 -->
      <div
        class="submit-btn"
        @click="handleSubmit"
      >
        <span>{{ $t('开户申请') }}</span>
        <img
          src="@/assets/images/banner/upper-right-arrow.png"
          alt=""
        >
      </div>
    </div>

    <CheckPhoneMessageDialog
      v-if="dialogVisible"
      :phone="formData.mobileNo"
      :country-code="formData.countryCode+''"
      :gee-info="geeInfo"
      :channelNum="getInfo.channelNum"
      :pageType="pageType"
      @close="dialogVisible = false; disabledButton = false"
      @confirm="onPhoneCodeCheck"
      @fail="sendFail"
      style="color: #222;"
    />
  </div>

</template>

<script>
import getInfoMixins from '@/mixins/form/getInfo.mixin'

export default {
  mixins: [getInfoMixins],
  props: {
    scroll: Boolean,
    showClose: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  // overflow: auto;
  .form {
    position: relative;
    width: 380px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 30px 30px;
    .close {
      position: absolute;
      right: 30px;
      top: 10px;
      width: 20px;
      height: 20px;
      background: url('~assets/images/dialog/icon-close.png') no-repeat
        center/cover;
      cursor: pointer;
      &:hover {
        background: url('~assets/images/dialog/icon-close-hover.png') no-repeat
          center/cover;
      }
    }
    .form-input {
      margin-bottom: 15px;
      padding-left: 20px;
      height: 40px;
      background: #f3f3f9;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .country-code-box {
      display: flex;
      align-items: center;
      margin-right: 25px;
      font-size: 14px;
      color: #182641;
      cursor: pointer;
      user-select: none;
      &.loading {
        opacity: 0.6;
        cursor: not-allowed;
      }
      .loading {
        display: flex;
        min-width: 38px;
        font-size: 18px;
      }
      .code {
        margin-right: 10px;
      }
      .arrow {
        width: 10px;
        height: 10px;
      }
    }
    .submit-btn {
      margin-top: 30px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: linear-gradient(90deg, #ffbb00 0%, #ff492d 100%);
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
      animation-name: flashover;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      span {
        font-size: 20px;
        color: #ffffff;
      }
      > img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
    @keyframes flashover {
      0% {
        transform: scale(0.92);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.92);
      }
    }
  }

  &.scroll {
    // margin-top: 20px;
    background: #313cb0;
    box-shadow: 0px 10px 16px 0px rgba(62, 81, 132, 0.2);
    border-radius: 10px;
    overflow: inherit;
    .form {
      width: 100%;
      background-color: transparent;
      padding: 30px;
      .form-input-container {
        display: flex;
        margin-bottom: 30px;
        .form-input {
          flex: 1;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 20px;
          height: 40px;
          background: #f3f3f9;
          border-radius: 4px;
          &:last-child {
            margin-bottom: 0;
            margin-right: 0;
          }
        }
      }
      /deep/ .radio-list {
        margin-top: 15px;

        .radio-item {
          flex: auto;
          color: #ffffff;
          width: auto;
        }
      }
      .submit-btn {
        width: 270px;
        margin: 0 auto;
      }
    }
  }
}

.opacity-enter-active {
  animation: opacity 0.2s;
}

/* 离开动画 */
.opacity-leave-active {
  animation: opacity 0.2s reverse;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.moveCartoon-enter-active {
  animation: move 0.3s;
}
.moveCartoon-leave-active {
  animation: move 0.3s reverse;
}

@keyframes move {
  from {
    transform: translatey(-10%);
  }
  to {
    transform: translate(0);
  }
}
@media screen and (max-width: @mobileWidth) {
  .form-container {
    margin-top: 25px;
    .form {
      width: 100%;
      border-radius: 10px;
      padding: 20px 20px 20px;
      .form-input {
        padding-left: 14px;
        height: 40px;
      }
      .country-code-box {
        margin-right: 24px;
        font-size: 12px;
      }
      .submit-btn {
        margin-top: 30px;
        height: 44px;
        line-height: 44px;
        span {
          font-size: 16px;
        }
        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
